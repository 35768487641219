// AdTitle.js
import React from 'react';
import './VideoTitle.css';

const VideoTitle = ({ adtitle, adAvatarUrl }) => {
  return (
    <div className="VideoTitle">
      <div className="AdvertiserAvatarContainer">
        <img
          src={adAvatarUrl}
          alt="Advertiser Avatar"
          className="AdvertiserAvatar"
        />
      </div>
      <div>
        <div className="VideoTitleText">{adtitle}</div>
        <div className="SponsorNote">sponsored</div>
      </div>
    </div>
  );
};

export default VideoTitle;
