import React from 'react';
import './AbcFooter.css';

const AbcFooter = () => {
  return (
    <footer className="AbcFooter">
      <div className="AbcFooterContent">
        <div className="AbcFooterTitle">
          <img src="/AbcFooterTitle.svg" alt="Ad-Based Crowdfunding Plan" />
        </div>
        <div className="AbcFooterDescription">
          Fuel better content with your attention.
          Every time you watch an ad, we pay the creator.
        </div>
      </div>
    </footer>
  );
};

export default AbcFooter; 