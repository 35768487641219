// src/components/NavPageCreator.js
import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

const NavPageCreator = () => {
  const location = useLocation();

  return (
    <Nav className="justify-content-center mb-4" variant="underline" activeKey={location.pathname}>
      <Nav.Item>
        <Nav.Link
          as={Link}
          to={`/${location.pathname.split('/')[1]}/comments`}
          eventKey={`/${location.pathname.split('/')[1]}/comments`}
          style={{ 
            fontWeight: 'normal', 
            color: location.pathname.includes('/comments') ? '#FFFFFF' : 'rgba(255, 255, 255, 0.5)', 
            fontSize: '0.9em' 
          }}
        >
          Comments
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          to={`/${location.pathname.split('/')[1]}/all`}
          eventKey={`/${location.pathname.split('/')[1]}/all`}
          style={{ 
            fontWeight: 'normal', 
            color: location.pathname.includes('/all') ? '#FFFFFF' : 'rgba(255, 255, 255, 0.5)', 
            fontSize: '0.9em' 
          }}
        >
          Bonus Content
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default NavPageCreator;
