// BrandLogoHeader.js
import React from 'react';
import './BrandLogoHeader.css';

const BrandLogoHeader = () => {

  return (
    <header className='brand-logo-header'>
      <div className="brand-logo-container">
        <div className='brand-logo-line-left'></div>
        <img
            alt="Eudia Logo"
            src="/brand-logo-35.svg"
            className="brand-logo-img"
        />
        <div className='brand-logo-line-right'></div>
      </div>
    </header>
  );
};

export default BrandLogoHeader;
