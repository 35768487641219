// AdPage.js
import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { db, functions, analytics } from  '../../utils/firebaseInit';
import { getFingerprint } from  '../../utils/fingerprint'; 
import { fetchIPAddress } from  '../../utils/fetchIP'; 
import { doc, getDocs, setDoc, collection, query, where, updateDoc, increment, serverTimestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { logEvent } from "firebase/analytics";


import './AdPage.css';
import RewardCard from './RewardCard';
import VideoTitle from './VideoTitle';
import VideoPlayer from './VideoPlayer';
import LoadingPage from '../LoadingPage';
import NotFoundPage from '../NotFoundPage';
import AbcFooter from './AbcFooter';

import BrandLogoHeader from '../../components/BrandLogoHeader';


const AdPage = () => {
  const { channelURL, shortLinkId } = useParams();
  const [ fullLinkId, setFullLinkId ] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [linkFail, setLinkFail] = useState(false);
  const [rewardData, setRewardData] = useState({
    reward_URL:'',
    reward_name:'',
  });
  const [creatorData, setCreatorData] = useState({
    creator_uid: '',
    channelName: '',
    channelPhoto: '',
    channelCategory: [],
  });
  const [selectedAdCreative, setSelectedAdCreative] = useState({
    brand_avatar: '',
    landing_page_url: '',
    video: '',
    video_title: '',
  });
  const [adId, setAdId] = useState('');
  const [adType, setAdType] = useState('');
  const [adBid, setAdBid] = useState(0);

  const [fingerprint, setFingerprint] = useState('');
  const [ipData, setIpData] = useState('');

  const now = useMemo(() => {
    const date = new Date();
    return date;
  }, []);
  const _yyyy_mm = '_' + now.getFullYear() + '_' + String(now.getMonth() + 1).padStart(2, '0');

  useEffect(() => {
    Promise.all([getFingerprint(), fetchIPAddress()])
      .then(([fingerprintId, ipAddress]) => {
        setFingerprint(fingerprintId);
        setIpData(ipAddress);
        // console.log('Fingerprint ID:', fingerprintId);
        // console.log('IP Address:', ipAddress);
      })
      .catch(error => {
        console.error('Error in fetching audience data:', error);
      });
  }, []);

  useEffect(() => {
    const fetchCreatorData = async () => {
      try {
        const qCreator = query(collection(db, 'Creators'), where('channelURL', '==', channelURL));
        const qCreatorSnapshot = await getDocs(qCreator);

        if (!qCreatorSnapshot.empty) {
          const docData = qCreatorSnapshot.docs[0].data();
          setCreatorData({
            creator_uid: docData.creator_uid,
            channelName: docData.channelName,
            channelPhoto: docData.channelPhoto,
            channelCategory: docData.channelCategory,
          });

          await fetchSelectedAdvertisement(docData.creator_uid, docData.channelCategory); //call cloud function API

        } else {
          console.error('ChannelURL does not exist.');
          setLinkFail(true);
          return;
        }
      } catch (error) {
        console.error('Error fetching Creator data:', error);
        setLinkFail(true);
      }
    };

    const fetchSelectedAdvertisement = async (creator_uid, channelCategory) => {
      try {
        // Call the Firebase Cloud Function to select the advertisement
        const selectAdvertisement = httpsCallable(functions, 'selectAdvertisement');
        const result = await selectAdvertisement( { creator_uid, channelCategory } );

        if (result.data.error) {
          console.error('Error selecting advertisement:', result.data.error);
          setLinkFail(true);
        } else {
          // Set the selected ad creative in your component state
          setSelectedAdCreative({
            brand_avatar: result.data.adCreative.brand_avatar,
            landing_page_url: result.data.adCreative.landing_page_url,
            video: result.data.adCreative.video,
            video_title: result.data.adCreative.video_title,
          });
          setAdId(result.data.id);
          setAdType(result.data.type);
          setAdBid(result.data.bid);

          // console.log('adCreative', result.data);
        }
      } catch (error) {
        console.error('Error fetching advertisement data:', error);
        setLinkFail(true);
      }
    };

    const fetchLinkData = async () => {
      try {
        const qLink = query(collection(db, 'Links'), where("shortLinkId", "==", shortLinkId));
        const qLinkSnapshot = await getDocs(qLink);

        if (!qLinkSnapshot.empty) {
          const linkDoc = qLinkSnapshot.docs[0];
          const linkData = linkDoc.data();
          const fullLinkId = linkDoc.id;
          setRewardData({
            reward_URL: linkData.reward_URL,
            reward_name: linkData.reward_name,
          }); 
          setFullLinkId(fullLinkId);
          
        } else {
          console.error('ShortLinkId does not exist.',shortLinkId);
          setLinkFail(true);
          return;
        }
      } catch (error) {
        console.error('Error fetching Link data:', error);
        setLinkFail(true);
      }
    };

    Promise.all([fetchCreatorData(), fetchLinkData()])
    .then(() => {
      setIsLoading(false); // Set isLoading to false when all operations are done.
    })
    .catch((error) => {
      console.error('Error in one or more async functions:', error);
      setIsLoading(false); // Set isLoading to false when an error occurs.
    });

  }, [channelURL, shortLinkId, _yyyy_mm]);

  useEffect(() => {
    // Check if the creatorData is loaded
    if (creatorData) {
      // Update the document title using the creator's name or any relevant information
      document.title = `${creatorData.channelName} | 觀影贊助區 | Eudia`;
    }
  }, [creatorData]); // Run this effect when creatorData changes


  // Firebase Analytic區
  const logWatchCheckpoint = (checkpoint) => {
    logEvent(analytics, 'ad_video_watch_checkpoint', {
      ad_ID: adId,
      videoCheckpoint: `${checkpoint}s`,
      creator_uid: creatorData.creator_uid,
    });
  };

  const logVideoCompletion = () => {
    logEvent(analytics, 'ad_video_completion', {
      ad_ID: adId,
      creator_uid: creatorData.creator_uid,
    });
  };

  const logCTAButtonClick = () => {
    logEvent(analytics, 'ad_video_CTA_click', {
      ad_ID: adId,
      creator_uid: creatorData.creator_uid,
    });
  };

  const logBonusCriteriaMet = () => {
    logEvent(analytics, 'ad_video_criteria_met', {
      ad_ID: adId,
      creator_uid: creatorData.creator_uid,
    });
  };

  const logVideoSoundOn = () => {
    logEvent(analytics, 'ad_video_sound_on', {
      ad_ID: adId,
      creator_uid: creatorData.creator_uid,
    });
  };

  useEffect(() => {
    logEvent(analytics, 'ad_video_exposure', {
      ad_ID: adId,
      creator_uid: creatorData.creator_uid,
    });
  }, [adId, creatorData.creator_uid]);

  // const rewardInfoLine1 = '感謝用行動支持創作者的你，';
  const rewardInfoLine1 = "Hi there! Thank you for taking a moment to support me. I really hope you enjoy the content!";
  // const rewardInfoLine2 = '觀看影片即可解鎖內容！';
  const rewardInfoLine2 = "";
  const creatorAvatarUrl = creatorData.channelPhoto;
  const creatorName = creatorData.channelName;
  const adTitle = selectedAdCreative.video_title;
  const adAvatarUrl = selectedAdCreative.brand_avatar;
  const adVideoUrl = selectedAdCreative.video;

  const bonusSeconds = 10; // 設定倒數秒數
  const logInterval = 5; // 設定紀錄續看率秒數

  const [remainingSeconds, setRemainingSeconds] = useState(bonusSeconds); 

  const [isAdClicked, setIsAdClicked] = useState(false);
  const [isCountdownOver, setIsCountdownOver] = useState(false);


  //成功進行一次「廣告曝光」區：
  const handleRemainingSecondsUpdate = async (seconds) => {
    setRemainingSeconds(seconds);
    if (seconds === 0 && !isCountdownOver) {
      logBonusCriteriaMet();
      try {
        setIsCountdownOver(true);
        await updateCampaignAdImpressions();
        await updateLinkAdImpressions();
        await updateCreatorAdImpressions();
        await updateAudienceAdImpressions();
      } catch (error) {
        console.error('Error updating ad impressions:', error);
      }
    }
  };

  const handleBonusButtonClick = () => {
    if(rewardData.reward_URL) {
      window.open(rewardData.reward_URL, '_blank');
    }
  };

  const handleCommentButtonClick = () => {
    const forumUrl = `/${channelURL}/comments`; // Constructs the path to the forum
    window.open(forumUrl, '_blank'); // Opens the forum in a new tab
  };
  

  const updateCampaignAdImpressions = async () => {
    try{
      const campaignRef = doc(db, 'Campaigns', adId);    
      const updateObj = {};
      updateObj['ad_impressions.now'] = increment(1);
      updateObj[`ad_impressions.${_yyyy_mm}`] = increment(1);
      if (adType === 'CPV') {
        updateObj['costs.total'] = increment(adBid);
        updateObj[`costs.${_yyyy_mm}.costs`] = increment(adBid);
      }
      await updateDoc(campaignRef, updateObj);
    } catch (error) {
      console.error('Error updating Campaign data:', error);
    }
  };

  const updateLinkAdImpressions = async () => {
    try {
      const linkRef = doc(db, 'Links', fullLinkId);
      const updateObj = {};
      updateObj['ad_impressions.total'] = increment(1);
      updateObj[`ad_impressions.${_yyyy_mm}`] = increment(1);
      await updateDoc(linkRef, updateObj);
    } catch (error) {
      console.error('Error updating Link data:', error);
    }
  };

  const updateCreatorAdImpressions = async () => {
    try {
      const creatorRef = doc(db, 'Creators', creatorData.creator_uid);
      const updateObj = {};
      updateObj['ad_impressions.total'] = increment(1);
      updateObj[`ad_impressions.${_yyyy_mm}`] = increment(1);
      await updateDoc(creatorRef, updateObj);
    } catch (error) {
      console.error('Error updating Creator data:', error);
    }
  };

  const updateAudienceAdImpressions = async () => {
    try {
      const audienceRef = doc(db, 'Audiences', `${fingerprint}_${ipData}`);
      const viewEventRef = doc(collection(audienceRef, 'view_events'), `view_event_${Date.now()}`);
  
      await setDoc(audienceRef, {
        fingerprint: fingerprint,
        ip_address: ipData,
        view_event_count: increment(1),
      }, { merge: true });
  
      await setDoc(viewEventRef, {
        view_time: serverTimestamp(),
        creator_uid: creatorData.creator_uid,
        campaign_id: adId,
      });
    } catch (error) {
      console.error('Error updating audience data:', error);
    }
  };


  //成功進行一次「廣告點擊」區：
  const handleLandingPageButtonClick = async () => {
    if (selectedAdCreative.landing_page_url && !isAdClicked) {
      window.open(selectedAdCreative.landing_page_url, '_blank');
      setIsAdClicked(true);  
      logCTAButtonClick();
      try {
        await updateCampaignAdClicks();
        await updateLinkAdClicks();
        await updateCreatorAdClicks();
        await updateAudienceAdClicks();        
      } catch (error) {
        console.error('Error updating ad clicks:', error);
      }  
    }
  };

  const updateCampaignAdClicks = async () => {
    try {
      const campaignRef = doc(db, 'Campaigns', adId);
      const updateObj = {};
      updateObj['ad_clicks.now'] = increment(1);
      updateObj[`ad_clicks.${_yyyy_mm}`] = increment(1);
      if (adType === 'CPC') {
        updateObj['costs.total'] = increment(adBid);
        updateObj[`costs.${_yyyy_mm}.costs`] = increment(adBid);
      }
      await updateDoc(campaignRef, updateObj);
    } catch (error) {
      console.error('Failed to update campaign ad clicks:', error);
    }
  };

  const updateLinkAdClicks = async () => {
    try {
      const linkRef = doc(db, 'Links', fullLinkId);
      const updateObj = {};
      updateObj['ad_clicks.total'] = increment(1);
      updateObj[`ad_clicks.${_yyyy_mm}`] = increment(1);
      await updateDoc(linkRef, updateObj);
    } catch (error) {
      console.error('Error updating Link data:', error);
    }
  };

  const updateCreatorAdClicks = async () => {
    try {
      const creatorRef = doc(db, 'Creators', creatorData.creator_uid);
      const updateObj = {};
      updateObj['ad_clicks.total'] = increment(1);
      updateObj[`ad_clicks.${_yyyy_mm}`] = increment(1);
      await updateDoc(creatorRef, updateObj);
    } catch (error) {
      console.error('Error updating Creator data:', error);
    }
  };

  const updateAudienceAdClicks = async () => {
    try {
      const audienceRef = doc(db, 'Audiences', `${fingerprint}_${ipData}`);
      const clickEventRef = doc(collection(audienceRef, 'click_events'), `click_event_${Date.now()}`);
  
      await setDoc(audienceRef, {
        fingerprint: fingerprint,
        ip_address: ipData,
        click_event_count: increment(1),
      }, { merge: true });
  
      await setDoc(clickEventRef, {
        click_time: serverTimestamp(),
        creator_uid: creatorData.creator_uid,
        campaign_id: adId,
      });
    } catch (error) {
      console.error('Error updating audience data:', error);
    }
  };


  if (isLoading) {
    return <LoadingPage />;
  }

  if(linkFail) {
    return <NotFoundPage />;
  }

  return (
    <div className="AdPage">
      <BrandLogoHeader />
      <div className="AdPageCardContainer">
        <RewardCard
          rewardInfoLine1={rewardInfoLine1}
          rewardInfoLine2={rewardInfoLine2}
          creatorAvatarUrl={creatorAvatarUrl}
          creatorName={creatorName}
          remainingSeconds={remainingSeconds}
          handleBonusButtonClick={handleBonusButtonClick}//領取獎勵按鈕
          handleCommentButtonClick={handleCommentButtonClick}//領取獎勵按鈕
          reward_URL={rewardData.reward_URL}
        />
      </div>

      <div className="AdPageCardContainer">
        <div className="VideoCard">
          <VideoTitle adtitle={adTitle} adAvatarUrl={adAvatarUrl} />
          <VideoPlayer 
            adVideoUrl={adVideoUrl}
            bonusSeconds={bonusSeconds} 
            onRemainingSecondsUpdate={handleRemainingSecondsUpdate}
            onWatchCheckpoint={logWatchCheckpoint}
            logInterval={logInterval}
            onVideoCompletion={logVideoCompletion}
            onSoundOn={logVideoSoundOn}
          />
          <button className="LandingPageButton" onClick={handleLandingPageButtonClick} disabled={isAdClicked}>
            <p className="my-auto">Learn More</p>
            {/* <i className="fa-solid fa-arrow-up-right-from-square"></i> */}
          </button>
        </div>
      </div>
      <AbcFooter />
    </div>
  );
};

export default AdPage;
