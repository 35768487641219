// RewardCard.js

import React from 'react';
import './RewardCard.css';

const RewardCard = ({ rewardInfoLine1, rewardInfoLine2, creatorAvatarUrl, creatorName, remainingSeconds, handleBonusButtonClick, handleCommentButtonClick, reward_URL }) => {
  return (
    <div className="RewardCard">
      {/* 上方內容區塊 */}
      <div className="RewardCardContentSection">
        {/* 左側頭像 */}
        <div className="AvatarContainer">
          <img src={creatorAvatarUrl} alt="Creator Avatar" className="CreatorAvatar" />
        </div>

        {/* 右側文字內容 */}
        <div className="RewardCardTextContent">
          <div className="CreatorName">{creatorName}</div>
          <div className="ThankYouText">
            <p>{rewardInfoLine1}</p>
            <p>{rewardInfoLine2}</p>
          </div>
        </div>
      </div>

      {/* 底部按鈕區塊 */}
      <div className="TimeAndButton">
        {remainingSeconds > 0 ? (
          <div className="RemainTime">
            {remainingSeconds} {remainingSeconds === 1 ? 'second' : 'seconds'} remaining
          </div>
        ) : (
          <div className="BonusButton">
            {reward_URL && (
              <button className="UnlockButton" onClick={handleBonusButtonClick}>
                Unlock Content
              </button>
            )}
            <button className="CommentLinkButton" onClick={handleCommentButtonClick}>
              View Comments
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RewardCard;