// CreatorInfo.js
import React from 'react';
import './CreatorInfo.css';

const CreatorInfo = ({ avatar, name }) => {
  return (
    <div className="main-creator">
      <img className='main-creator-avatar' alt="Creator Avatar" src={avatar} />
      <div className="main-creator-name">
        <h4>{name}</h4>
      </div>
    </div>
  );
};

export default CreatorInfo;