// CommentSubject.js
import React, { useState } from 'react';
import SubjectModal from './SubjectModal';
import './CommentSubject.css';

const CommentSubject = ({ isCreator, creatorUid, subject }) => {
  const [showSubjectModal, setShowSubjectModal] = useState(false);
  const toggleSubjectModal = () => setShowSubjectModal(prev => !prev);

  return (
    <div className="comment-subject-container">
      {subject && (
        <div className="comment-subject">
          <div className='comment-subject-note'>
            Let’s chat about:
          </div>
          <h3>{subject}</h3>
        </div>
      )}
      {isCreator && (
        <button
          type="button"
          className={`btn ${subject ? 'btn-outline-secondary' : 'btn-success'} btn-sm rounded-pill`}
          onClick={toggleSubjectModal}
        >
          <span>{subject ? '編輯主題' : '新增討論主題'}</span>
          <i className='fa-solid fa-file-pen ms-1'></i>
        </button>
      )}
      {isCreator && (
        <SubjectModal
          show={showSubjectModal}
          handleClose={toggleSubjectModal}
          creatorUid={creatorUid}
          commentSubject={subject}
        />
      )}
    </div>
  );
};

export default CommentSubject;
